$(document).ready(function () {
  const $slickSlider = $("#facts__slider");
  const factSliderSettings = {
    mobileFirst: true,
    dots: true,
    prevArrow: false,
    nextArrow: false,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 767,
        settings: "unslick",
      },
    ],
  };

  $slickSlider.slick(factSliderSettings);

  // reslick only if it's not slick()
  $(window).on("resize load", function () {
    if (!$slickSlider.hasClass("slick-initialized")) {
      return $slickSlider.slick(factSliderSettings);
    }
  });
});

document.addEventListener("DOMContentLoaded", () => {
  const navMobileOpenClass = "nav--mobile-open"

  const nav = document.querySelector(".nav")
  const navButton = document.querySelector(".nav__button")

  const isMobileNavOpen = () => nav.classList.contains(navMobileOpenClass)

  navButton.addEventListener("click", () => {
    nav.classList.toggle(navMobileOpenClass)
    navButton.setAttribute(
      "aria-expanded",
      isMobileNavOpen() ? "true" : "false"
    )
    document.body.classList.toggle("noscroll")
  })

  // subnavs
  const subnavButtons = document.querySelectorAll(".nav__sub-button")

  // close all subnavs when the escape key is pressed
  const closeAllSubNavs = () => {
    nav.querySelectorAll("button[aria-expanded='true']").forEach((btn) => {
      btn.setAttribute("aria-expanded", "false")
    })
  }

  const onEsc = (e) => {
    if (e.key === "Escape") {
      closeAllSubNavs()
    }
  }

  document.addEventListener("keydown", onEsc)

  // close on outside click
  document.addEventListener("click", (e) => {
    if (!nav.contains(e.target)) {
      closeAllSubNavs()
    }
  })

  // close all other subnavs on same level when one is opened
  ;[1, 2].forEach((level) => {
    document
      .querySelectorAll(`.nav__sub-button[data-level='${level}']`)
      .forEach((button, _, buttons) => {
        button.addEventListener("click", (e) => {
          buttons.forEach((button) => {
            if (button !== e.target) {
              button.setAttribute("aria-expanded", "false")
            }
          })
        })
      })
  })

  subnavButtons.forEach((button) => {
    button.addEventListener("click", (e) => {
      console.log("click");
      if (window.FloatingUIDOM) {
        const isSubNavOpen = () =>
          button.getAttribute("aria-expanded") === "true"
        const subnav =
          button.nextElementSibling.querySelector(".nav__sub-items")
        const hasSubNav = subnav !== null

        // close via esc key

        if (isSubNavOpen()) {
          if (hasSubNav) {
            closeAllSubNavs()
          } else {
            button.setAttribute("aria-expanded", "false")
          }
        } else {
          button.setAttribute("aria-expanded", "true")

          if (button.nextElementSibling) {
            const { computePosition, autoPlacement, autoUpdate, flip } =
              window.FloatingUIDOM
            const subnav = button.nextElementSibling

            autoUpdate(button, subnav, () => {
              computePosition(button, subnav, {
                placement:
                  button.dataset.level === "1" ? "bottom-start" : "right-start",
                middleware: [
                  flip({
                    fallbackPlacements:
                      button.dataset.level === "1"
                        ? ["bottom", "bottom-start", "bottom-end"]
                        : [
                            "right-start",
                            "right-end",
                            "right",
                            "left-start",
                            "left-end",
                            "left",
                          ],
                  }),
                ],
              }).then(({ x, y }) => {
                Object.assign(subnav.style, {
                  top: `${y}px`,
                  left: `${x}px`,
                })
              })
            })
          }
        }
      }
    })
  })
});

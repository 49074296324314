// // Show and hide Infos
function hideAllInfos() {
  $(".contact-form__info").removeClass("contact-form__info--active");
}

export function bindFocusEventHandler(fieldName) {
  const inputSelector = '[data-toggle-focus="input-' + fieldName + '"]';
  const elementSelector = '[data-toggle-focus="element-' + fieldName + '"]';
  const input = $(inputSelector);
  const element = $(elementSelector);

  input.focus(function () {
    hideAllInfos();
    element.addClass("contact-form__info--active");
  });
}

export function bindBlurEventHandler(fieldName) {
  const inputSelector = '[data-toggle-focus="input-' + fieldName + '"]';

  $(inputSelector).blur(function () {
    hideAllInfos();
    $(".contact-form__leave-focus-info").addClass("contact-form__info--active");
  });
}

// functions for Steps
export function disableButton($button) {
  $button.addClass("button--disabled");
}

export function enableButton($button) {
  $button.removeClass("button--disabled");
}

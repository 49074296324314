import Mailcheck from 'mailcheck';

$(document).ready(function() {
  $('#user_email').on('blur', function() {
    console.log("run mailcheck");
    Mailcheck.run({
      email:  $('#user_email').val(),
      suggested: function(suggestion) {
        $('.contact-form__suggestions').html('');
        const $suggestion = $('<span>Meinten Sie: <strong>' + suggestion.full + '</strong></span>');
        $suggestion.on('click', function() {
          $('#user_email').val(suggestion.full);
          $('.contact-form__suggestions').html('');
        });
        const $suggestionsContainer = $('.contact-form__suggestions');
        $suggestionsContainer.append($suggestion);
      },
      empty: function() {
        $('.contact-form__suggestions').html('');
      }
    });
  });
});

$(document).ready(function(){
  $('#press__slider').slick({
    mobileFirst: true,
    prevArrow: ".press__prevArrow",
    nextArrow: ".press__nextArrow",
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4
        }
      }
    ]
  });

  // set same height for each slide
  setSlideHeight('#press__slider');

  // set same height for each slide after position/size changes
  $('#press__slider').on('setPosition', function () {
    setSlideHeight(this);
  });

  function setSlideHeight(slider) {
    $(slider).find('.slick-slide').height('auto');
    var slickTrack = $(slider).find('.slick-track');
    var slickTrackHeight = $(slickTrack).height();
    $(slider).find('.slick-slide').css('height', slickTrackHeight + 'px');
  };
});
$(document).ready(function() {
  if ($('#lead_motivation').length)
    validateMotivation();
});

function validateMotivation() {
  switchButtonMotivation();

  $('#lead_motivation').on('keyup paste', function() {
    switchButtonMotivation();
  });
};

function switchButtonMotivation() {
  if ($('#lead_motivation')[0].value.length !== 0) {
    $('#motivation-button').val('weiter');
  } else {
    $('#motivation-button').val('überspringen');
  }
}

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'jquery';
import 'jquery-validation';
import 'slick-carousel-browserify';
// import "javascripts/header";
import 'javascripts/frontpage/nav';
import 'javascripts/content-page';
import 'javascripts/product-page';
import 'javascripts/frontpage/stageslider';
import 'javascripts/frontpage/facts';
import 'javascripts/frontpage/video';
import 'javascripts/frontpage/blogs';
import 'javascripts/frontpage/tabs';
import 'javascripts/frontpage/press';
import 'javascripts/flash-message-close';
import 'javascripts/frontpage/single-gallery-slider';
import 'javascripts/contact/contact';
import 'javascripts/contact/new';
import 'javascripts/contact/steps/request-reason';
import 'javascripts/contact/steps/upload-file';
import 'javascripts/contact/steps/link-to-job';
import 'javascripts/contact/steps/qualification';
import 'javascripts/contact/steps/motivation';
import 'javascripts/contact/steps/language';
import 'javascripts/gallery-image-container/gallery-image-container-slider';
import 'javascripts/backend/editor';
import 'javascripts/shared/mailcheck';

import Rails from '@rails/ujs';

Rails.start();

$(document).ready(function(){
  const blogSliderSettings = {
    mobileFirst: true,
    dots: true,
    prevArrow: false,
    nextArrow: false,
    autoplay: true,
    autoplaySpeed: 4000,
    centerMode: true,
    variableWidth: true,
    infinite: false
  };

  if ($(window).width() < 1024) {
    $('#blogs__slider').slick(blogSliderSettings);
  }

  // reslick only if it's not slick()
  $(window).on('resize', function() {
    if ($(window).width() > 1023) {
      if ($('#blogs__slider').hasClass('slick-initialized')) {
        $('#blogs__slider').slick('unslick');
      }
      return
    }

    if (!$('#blogs__slider').hasClass('slick-initialized')) {
      return $('#blogs__slider').slick(blogSliderSettings);
    }
  });
});
import MediumEditor from 'medium-editor'

import NoFollowForm from './editor_custom_anchor'

$(document).ready(function(){
  const textBoldEditor = new MediumEditor('.js-text-editor--bold', {
    toolbar: {
      buttons: [
        'bold',
        'italic'
      ]
    }
  });

  const textBoldLinkEditor = new MediumEditor('.js-text-editor--bold-link', {
    extensions: {
      'customanchor': new NoFollowForm()
    },
    toolbar: {
      buttons: [
        'customanchor',
        'bold',
        'italic',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull'
      ]
    }
  });

  const textLinkEditor = new MediumEditor('.js-text-editor---link', {
    extensions: {
      'customanchor': new NoFollowForm()
    },
    toolbar: {
      buttons: [
        'customanchor',
        'bold',
        'italic',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
      ]
    }
  });
});

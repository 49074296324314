import { bindFocusEventHandler } from '../contact';

$(document).ready(function () {
  // show and hide infos focusing input-field
  bindFocusEventHandler("request_reason_folder");
  bindFocusEventHandler("request_reason_letter");
  bindFocusEventHandler("request_reason_cv");
  bindFocusEventHandler("request_reason_review");
  bindFocusEventHandler("request_reason_other");

  // validateRequestReason();
});

$(document).ready(function () {
  // hide side-navi for big element
  if ($("#js-side-navigation").length && $(".js-highlight-container").length) {
    const sideNav = document.querySelector("#js-side-navigation");
    const asides = Array.from(
      document.querySelectorAll(".js-highlight-container")
    );

    const navStart = sideNav.offsetTop;
    const navHeight = sideNav.offsetHeight;
    const navEnd = navStart + navHeight;

    const getAsideStarts = () => asides.map((aside) => aside.offsetTop);
    const getAsideHeights = () => asides.map((aside) => aside.offsetHeight);
    const getAsideEnds = () =>
      asideStarts.map((offset, i) => offset + asideHeights[i]);

    let asideStarts;
    let asideHeights;
    let asideEnds;

    const updateAsides = () => {
      asideStarts = getAsideStarts();
      asideHeights = getAsideHeights();
      asideEnds = getAsideEnds();
    };

    updateAsides();

    window.addEventListener("scroll", () => {
      const intersects = asideStarts.some((asideStart, i) => {
        const asideEnd = asideEnds[i];
        return (
          asideStart - navEnd <= pageYOffset &&
          !(asideEnd <= pageYOffset + navStart)
        );
      });
      sideNav.classList.toggle("js-hide-sidebar", intersects);
    });

    window.addEventListener("resize", updateAsides);
  }

  // show back to-top-button only on deep scroll
  $(window).on("scroll", function (event) {
    $("#js-button-back-to-top").toggleClass(
      "js-show-button-back-to-top",
      $(window).scrollTop() > 600
    );
  });
});

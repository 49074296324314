$(document).ready(function(){

  if($('.product-page__header-folder-image-container').length){
    setSidebarAsScrollContainer();

    // animate header image product page
    let folderTop = $('.product-page__header-folder-image-container')[0].offsetTop
    let openFolderAt = folderTop - $(window).innerHeight() / 0.75
    let closeFolderAt = folderTop

    if ($(window).innerWidth() > 768){
      $('#js-product-image--middle, #js-product-image--top').addClass('active')
    }

    $(window).on('scroll', function(event) {
      $('#js-product-image--middle, #js-product-image--top').toggleClass('active',
        (
          $(window).scrollTop() > openFolderAt
          && $(window).scrollTop() < closeFolderAt
        )
      );
    });


    // scroll function on click on arrow
    $('.product-page__sidebar-scroll-icon').on('click', function(event){
      $('.product-page__sidebar-container').stop().animate({
        scrollTop: $('.product-page__sidebar-container').innerHeight()
      }, 850);
    });

    // show scroll-arrow
    window.addEventListener('resize', setSidebarAsScrollContainer);
  }
});

function setSidebarAsScrollContainer() {
  const $sidebar = $('.product-page__sidebar-container');
  const $sidebarLinkList = $sidebar.find('.table-of-contents__list');
  if ($sidebar.innerHeight() >= $sidebarLinkList.innerHeight()) {
    $('.product-page__sidebar-scroll-icon').hide();
    $sidebar.css("overflow-y", "unset");
  }
  else {
    $('.product-page__sidebar-scroll-icon').show();
    $sidebar.css("overflow-y", "scroll");
  }
}

$(function () {
  var video = $(".video__video-wrapper")[0];
  let videoElementClicked = false;
  $(".video__link--open, .video__poster, .video__icon").on(
    "click",
    function () {
      $(".video__lightbox").fadeIn().css("display", "flex");
      video.play();
    }
  );
  // Finds all iframes from youtubes and gives them a unique class
  // $(".video iframe").addClass("video__youtube-iframe");
  $("#video__video-element").on("click", function () {
    videoElementClicked = true;
    if (video.paused) {
      video.play();
    }
    $(".video__lightbox").fadeOut();
  });
  $(".video__lightbox").on("click", function () {
    $(".video__lightbox").fadeOut();
    if (videoElementClicked === false) {
      video.pause();
    }
    videoElementClicked = false;
  });
  $(".video__link--close").on("click", function () {
    video.pause();
  });
});

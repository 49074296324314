$(document).ready(function(){
  $galleryImageContainerNavitaion = $('#js-gallery-image-container__navigation')

  if ($galleryImageContainerNavitaion.parent().parent().hasClass('js-content-gallery')) {
    $galleryImageContainerNavitaion.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '#js-gallery-image-container__slider',
      dots: false,
      prevArrow: ".gallery-image-container__prevArrow",
      nextArrow: ".gallery-image-container__nextArrow",
      centerMode: true,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  } else {
    $galleryImageContainerNavitaion.slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: '#js-gallery-image-container__slider',
      dots: false,
      prevArrow: ".gallery-image-container__prevArrow",
      nextArrow: ".gallery-image-container__nextArrow",
      centerMode: true,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  }

  $galleryImageContainerNavitaion.on('click', function(){
    $('.gallery-images__slider').each(function(){
      $(this).slick('slickGoTo', 0);
    });
  });

  $('#js-gallery-image-container__slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '#js-gallery-image-container__navigation',
    swipe: false,
    adaptiveHeight: true
  });

  // Inner Gallery
  $galleryImagesSlider = $('div[id^="js-gallery-images__slider--"]');

  $galleryImagesSlider.each(function(index){
    prevArrowSelector = ".gallery-images__prevArrow--" + index
    nextArrowSelector = ".gallery-images__nextArrow--" + index

    const $slider = $(this);

    sliderSettings = {
      prevArrow: $(prevArrowSelector),
      nextArrow: $(nextArrowSelector),
      infinite: false,
      fade: true
    }

    if ($(window).width() > 680) {
      $slider.slick(sliderSettings);
    }

    // reslick only if it's not slick()
    $(window).on('resize', function() {
      if ($(window).width() < 680) {
        if ($slider.hasClass('slick-initialized')) {
          $slider.slick('unslick');
        }
        return
      }

      if (!$slider.hasClass('slick-initialized')) {
        return $slider.slick(sliderSettings);
      }
    });

    // set functions on first and last arrow of slide
    const totalSlides = $slider.find('.slick-slide').length - 1;
    const $allImageSliders = $('.gallery-images__slider');
    const $prevArrow = $(prevArrowSelector);
    const $nextArrow = $(nextArrowSelector);

    const slickPrevNavigation = function(){
      // console.log('slickPrevNavigation')

      // set last slide as first slide of all sliders
      $allImageSliders.each(function(){
        $(this).slick('slickGoTo', $(this).find('.slick-slide').length);
      });
      $galleryImageContainerNavitaion.slick('slickPrev');
    };

    const slickNextNavigation = function(){
      // console.log('slickNextNavigation')

      // set first slide as first slide of all sliders
      $allImageSliders.each(function(){
        $(this).slick('slickGoTo', 0);
      });
      $galleryImageContainerNavitaion.slick('slickNext');
    };

    const slickPrevSlider = function(event){
      // console.log('slickPrevSlider')

      $slider.slick('slickPrev');
      event.stopPropagation();
      event.preventDefault();
    };

    const slickNextSlider = function(event){
      // console.log('slickNextSlider')

      $slider.slick('slickNext');
      event.stopPropagation();
      event.preventDefault();
    };

    // inital values
    $prevArrow.on('click', slickPrevNavigation);

    // show arrows even if only one image
    if (totalSlides <= 1) {
      $prevArrow.show();
      $prevArrow.off('click', slickPrevNavigation);
      $prevArrow.on('click', slickPrevNavigation);

      $nextArrow.show();
      $prevArrow.off('click', slickNextNavigation);
      $nextArrow.on('click', slickNextNavigation);
    }

    $slider.on('afterChange', function(){
      const totalSlides = $(this).find('.slick-slide').length;
      const currentSlide = $(this).slick('slickCurrentSlide') + 1;

      $prevArrow.off();
      $nextArrow.off();

      // first slide
      if (currentSlide == 1) {
        $prevArrow.on('click', slickPrevNavigation);
        $nextArrow.on('click', slickNextSlider);

      // last slide
      } else if (currentSlide == totalSlides) {
        $nextArrow.on('click', slickNextNavigation);
        $prevArrow.on('click', slickPrevSlider);
      }

      // middle slides
      if (currentSlide > 1 && currentSlide < totalSlides) {
        $prevArrow.on('click', slickPrevSlider);
        $nextArrow.on('click', slickNextSlider);
      }
    });
  });
});
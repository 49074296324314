$(document).ready(function () {
  // hide language textfield
  if (document.getElementById("contact-form__language")) {
    const leadAdditionalLanguageInput = $("#lead_additional_language");
    const leadAdditionalLanguageCheckbox = $("#additional_language_checkbox");

    if (leadAdditionalLanguageInput.val()) {
      leadAdditionalLanguageCheckbox.prop("checked", true);
      leadAdditionalLanguageInput.parent().show(200);
    } else {
      leadAdditionalLanguageCheckbox.prop("unchecked", false);
    }

    leadAdditionalLanguageCheckbox.change(function () {
      if (leadAdditionalLanguageCheckbox.is(":checked")) {
        leadAdditionalLanguageInput.parent().show(200);
      } else {
        leadAdditionalLanguageInput.parent().hide(200);
        leadAdditionalLanguageInput.val("");
      }
    });

    switchButtonLanguage();

    document
      .getElementById("lead_additional_language")
      .addEventListener("input", function () {
        switchButtonLanguage();
      });

    $(this).on("change", function () {
      switchButtonLanguage();
    });
  }
});

function switchButtonLanguage() {
  const leadAdditionalLanguageInputValueLongerThanOneChar =
    document.getElementById("lead_additional_language").value.length > 1;
  const leadGermanRequested = $("#lead_german_requested");
  const leadEnglishRequested = $("#lead_english_requested");

  if (
    leadAdditionalLanguageInputValueLongerThanOneChar ||
    leadGermanRequested.is(":checked") ||
    leadEnglishRequested.is(":checked")
  ) {
    $("#language-button").val("weiter");
  } else {
    $("#language-button").val("überspringen");
  }
}

$(document).ready(function () {
  if ($("#lead_qualification").length) validateQualification();
});

function validateQualification() {
  switchButtonQualification();

  $("#lead_qualification").on("keyup paste", function () {
    switchButtonQualification();
  });
}

function switchButtonQualification() {
  if ($("#lead_qualification")[0].value.length !== 0) {
    $("#qualification-button").val("weiter");
  } else {
    $("#qualification-button").val("überspringen");
  }
}

import Dropzone from 'dropzone';

Dropzone.autoDiscover = false;

$(document).ready(function () {
  const $fileInput = $("#fileInput");
  if ($fileInput.length) {
    const uploadPath = $fileInput.data("upload-path");
    const existingFilesPath = $fileInput.data("existing-files-path");
    const removePath = $fileInput.data("remove-path");

    const fileDropzone = new Dropzone($fileInput.get(0), {
      url: uploadPath,
      dictDefaultMessage: "Weitere Dateien hochladen.",
      dictCancelUpload: "Upload abbrechen",
      dictCancelUploadConfirmation: "Upload sicher abbrechen?",
      addRemoveLinks: true,
      dictRemoveFile: "",
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      },
      uploadprogress(file, progress, bytesSent) {
        if (file.previewElement) {
          for (let node of file.previewElement.querySelectorAll(
            "[data-dz-uploadprogress]"
          )) {
            node.nodeName === "PROGRESS"
              ? (node.value = progress)
              : (node.style.width = `${progress}%`);
            $(".dz-progress").attr("data-value", `${progress.toFixed(2)}%`);
            $(".dz-upload").attr("data-value", `${progress.toFixed(2)}%`);
          }
        }
      },
      success: function (file, response) {
        var imgName = file.name;
        file.previewElement.classList.add("dz-success");
        $("#upload-file-button").val("weiter");

        // google analytics
        if ($("body").attr("data-environment") == "production") {
          window.ga(
            "send",
            "event",
            "Kontaktformular",
            "Virtual Pageview",
            "Datei hochgeladen"
          );
          // console.log('send', 'event', 'Kontaktformular', 'Virtual Pageview', 'Datei hochgeladen');
        }
      },
      error: function (file, response) {
        file.previewElement.classList.add("dz-error");
        console.log("error", response);
      },
      init: function () {
        const thisDropzone = this;
        $.get(existingFilesPath, function (data) {
          if (data == null) {
            return;
          }

          $.each(data, function (key, value) {
            var mockFile = { id: value.id, name: value.name, type: value.type };
            thisDropzone.emit("addedfile", mockFile);

            if (value.type.match(/image*/)) {
              thisDropzone.options.thumbnail.call(
                thisDropzone,
                mockFile,
                value.path
              );
            }
            // Make sure that there is no progress bar, etc...
            thisDropzone.emit("complete", mockFile);
          });
        });
      },
    });

    fileDropzone.on("removedfile", function (file) {
      $.get(removePath, { file_id: file.id });
    });
  }

  const $curriculumVitaeFileInput = $("#curriculumVitaeFileInput");
  if ($curriculumVitaeFileInput.length) {
    const uploadPath = $curriculumVitaeFileInput.data("upload-path");
    const existingFilesPath = $curriculumVitaeFileInput.data(
      "existing-files-path"
    );
    const removePath = $curriculumVitaeFileInput.data("remove-path");

    const fileDropzone = new Dropzone($curriculumVitaeFileInput.get(0), {
      url: uploadPath,
      dictDefaultMessage: "Lebenslauf hochladen.",
      dictCancelUpload: "Upload abbrechen",
      dictCancelUploadConfirmation: "Upload sicher abbrechen?",
      addRemoveLinks: true,
      dictRemoveFile: "",
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      },
      uploadprogress(file, progress, bytesSent) {
        if (file.previewElement) {
          for (let node of file.previewElement.querySelectorAll(
            "[data-dz-uploadprogress]"
          )) {
            node.nodeName === "PROGRESS"
              ? (node.value = progress)
              : (node.style.width = `${progress}%`);
            $(".dz-progress").attr("data-value", `${progress.toFixed(2)}%`);
            $(".dz-upload").attr("data-value", `${progress.toFixed(2)}%`);
          }
        }
      },
      success: function (file, response) {
        var imgName = file.name;
        file.previewElement.classList.add("dz-success");
        $("#upload-file-button").val("weiter");

        file.blob_id = response.blob_id;

        // google analytics
        if ($("body").attr("data-environment") == "production") {
          window.ga(
            "send",
            "event",
            "Kontaktformular",
            "Virtual Pageview",
            "Datei hochgeladen"
          );
          // console.log('send', 'event', 'Kontaktformular', 'Virtual Pageview', 'Datei hochgeladen');
        }
      },
      error: function (file, response) {
        file.previewElement.classList.add("dz-error");
        console.log("error", response);
      },
      init: function () {
        const thisDropzone = this;
        $.get(existingFilesPath, function (data) {
          if (data == null) {
            return;
          }

          $.each(data, function (key, value) {
            var mockFile = {
              blob_id: value.blob_id,
              name: value.name,
              type: value.type,
            };
            thisDropzone.emit("addedfile", mockFile);

            if (value.type.match(/image*/)) {
              thisDropzone.options.thumbnail.call(
                thisDropzone,
                mockFile,
                value.path
              );
            }
            // Make sure that there is no progress bar, etc...
            thisDropzone.emit("complete", mockFile);
          });
        });
      },
    });

    fileDropzone.on("removedfile", function (file) {
      $.get(removePath, { blob_id: file.blob_id });
    });
  }
});

import {
  bindBlurEventHandler,
  bindFocusEventHandler,
} from './contact';

$(document).ready(function() {
  // set Start-Infos
  showStartInfo();

  // show start message on leaving field
  bindBlurEventHandler('name');
  bindBlurEventHandler('email');
  bindBlurEventHandler('phone');

  // show and hide infos focusing input-field
  // same info for first_name and last_name
  bindFocusEventHandler('name')
  bindFocusEventHandler('email');
  bindFocusEventHandler('phone');

  // Validation
  validateNew();
});

function showStartInfo() {
  $('.contact-form__leave-focus-info').removeClass('contact-form__info--first-active');
  $('.contact-form__leave-focus-info').addClass('contact-form__info--active');
}

function validateNew() {
  $("#contact-form__form").validate({
    errorClass: "contact-form__error",
    wrapper: "span",
    rules: {
      // The key name on the left side is the name attribute
      // of an input field. Validation rules are defined
      // on the right side
      'user[first_name]': "required",
      'user[last_name]': "required",
      'user[email]': {
        required: true,
        // Specify that email should be validated
        // by the built-in "email" rule
        email: true
      },
      'user[terms_accepted]': "required",
      'user[phone]': {
        required: false
      }
    },
    // Specify validation error messages
    messages: {
      'user[first_name]': "Bitte geben Sie Ihren Vornamen an.",
      'user[last_name]': "Bitte geben Sie Ihren Nachnamen an.",
      'user[email]': "Bitte geben Sie Ihre E-Mail-Adresse an.",
      'user[terms_accepted]': "Bitte stimmen Sie unseren AGB und Datenschutz&shy;bestim&shy;mung&shy;en zu."
    }
    // Make sure the form is submitted to the destination defined
    // in the "action" attribute of the form when valid
    // submitHandler: function (form) {
    //    form.submit();
    // }
  });
}

import Dropzone from 'dropzone';

Dropzone.autoDiscover = false;

$(document).ready(function () {
  if (document.getElementById("contact-form__link-job-ad")) {
    if ($("#lead_link_to_job_ad, #lead_job_description").length) {
      validatelinkToJob();
    }

    // hide job ad textfields
    const leadJobAdInfoInputs = $(".js-hide-job-ad-field");
    const leadJobAdInfoCheckbox = $("#job_ad_info__checkbox");
    const leadLinkToJobInput = document.getElementById("lead_link_to_job_ad");

    if (leadLinkToJobInput.value.length > 1) {
      leadJobAdInfoCheckbox.prop("checked", true);
      leadJobAdInfoInputs.show(200);
    } else {
      leadJobAdInfoInputs.hide();
    }

    leadJobAdInfoCheckbox.change(function () {
      if (leadJobAdInfoCheckbox.is(":checked")) {
        leadJobAdInfoInputs.show(200);
      } else {
        leadJobAdInfoInputs.hide(200);
        leadJobAdInfoInputs.val("");
      }
    });

    // hide unsolicited_application input
    const leadJobDescriptionInput = $("#lead_job_description");
    const leadUnsolicitedApplicationCheckbox = $(
      "#lead_unsolicited_application"
    );

    if (leadJobDescriptionInput.val()) {
      leadUnsolicitedApplicationCheckbox.prop("checked", true);
      leadJobDescriptionInput.show(200);
    } else {
      leadJobDescriptionInput.hide();
    }
    showJobdescriptionFieldIfCheckBoxIsChecked();

    leadUnsolicitedApplicationCheckbox.change(function () {
      showJobdescriptionFieldIfCheckBoxIsChecked();
    });

    const $jobAdFileInput = $("#lead_job_ad_file");
    if ($jobAdFileInput) {
      const jobAdUploadPath = $jobAdFileInput.data("upload-job-ad-path");
      const existingJobAdFilesPath = $jobAdFileInput.data(
        "existing-job-ad-files-path"
      );
      const removeJobAdPath = $jobAdFileInput.data("remove-job-ad-path");

      const jobAdFileDropzone = new Dropzone($jobAdFileInput.get(0), {
        url: jobAdUploadPath,
        dictDefaultMessage: "Dateiupload (PDF oder Foto der Stellenanzeige)",
        addRemoveLinks: true,
        dictRemoveFile: "",
        headers: {
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
        success: function (file, response, data) {
          var imgName = file.name;
          file.previewElement.classList.add("dz-success");
          $("#link-to-job-button").val("weiter");

          file.blob_id = response.blob_id;

          // google analytics
          if ($("body").attr("data-environment") == "production") {
            window.ga(
              "send",
              "event",
              "Kontaktformular",
              "Virtual Pageview",
              "Stellenanzeige hochgeladen"
            );
            // console.log('send', 'event', 'Kontaktformular', 'Virtual Pageview', 'Stellenanzeige hochgeladen');
          }
        },
        error: function (file, response) {
          file.previewElement.classList.add("dz-error");
          console.log("error", response);
        },
        init: function () {
          const thisDropzone = this;
          $.get(existingJobAdFilesPath, function (data) {
            if (data == null) {
              return;
            }

            $.each(data, function (key, value) {
              var mockFile = {
                blob_id: value.blob_id,
                name: value.name,
                type: value.type,
              };
              thisDropzone.emit("addedfile", mockFile);

              if (value.type.match(/image*/)) {
                thisDropzone.options.thumbnail.call(
                  thisDropzone,
                  mockFile,
                  value.path
                );
              }
              // Make sure that there is no progress bar, etc...
              thisDropzone.emit("complete", mockFile);
            });
            if (data.length > 0) {
              leadJobAdInfoCheckbox.prop("checked", true);
              leadJobAdInfoInputs.show();
              switchButtonLinkToJobAd(data.length);
            }
          });
        },
      });

      jobAdFileDropzone.on("removedfile", function (file) {
        $.get(removeJobAdPath, { blob_id: file.blob_id });
        switchButtonLinkToJobAd(jobAdFileDropzone.files.length);
      });
    }
  }
});

function validatelinkToJob() {
  switchButtonLinkToJobAd();

  $("#lead_link_to_job_ad, #lead_job_description").on(
    "keyup paste",
    function () {
      switchButtonLinkToJobAd();
    }
  );

  $("#lead_unsolicited_application").on("click", function () {
    switchButtonLinkToJobAd();
  });
}

function showJobdescriptionFieldIfCheckBoxIsChecked() {
  const leadJobDescriptionInput = $("#lead_job_description");
  const leadUnsolicitedApplicationCheckbox = $("#lead_unsolicited_application");
  if (leadUnsolicitedApplicationCheckbox.is(":checked")) {
    leadJobDescriptionInput.show(200);
  } else {
    leadJobDescriptionInput.hide(200);
    leadJobDescriptionInput.val("");
  }
}

function switchButtonLinkToJobAd(amount = 0) {
  if (
    $("#lead_link_to_job_ad")[0].value.length !== 0 ||
    $("#lead_job_description")[0].value.length !== 0 ||
    $("#lead_unsolicited_application")[0].checked ||
    amount > 0
  ) {
    $("#link-to-job-button").val("weiter");
  } else {
    $("#link-to-job-button").val("überspringen");
  }
}

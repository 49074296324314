
$(document).ready(function(){
  $('#folder__slider img').addClass('visible');

  const stageSliderSettings = {
    dots: false,
    prevArrow: false,
    nextArrow: false,
    autoplay: true,
    autoplaySpeed: 4000,
    fade: true,
    cssEase: 'ease-in-out'
  };

  if ($(window).width() > 767) {
    $('#folder__slider').slick(stageSliderSettings);
  }

  // reslick only if it's not slick()
  $(window).on('resize', function() {
    if ($(window).width() < 768) {
      if ($('#folder__slider').hasClass('slick-initialized')) {
        $('#folder__slider').slick('unslick');
      }
      return
    }

    if (!$('#folder__slider').hasClass('slick-initialized')) {
      return $('#folder__slider').slick(stageSliderSettings);
    }
  });

  $('#js-scroll-arrow').on('click', function() {
    $('html, body').animate({ scrollTop: ($('.facts').offset().top) - 40}, 'slow');
  });
});
